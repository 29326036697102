import * as React from "react"
import { motion, useTransform, useSpring, useScroll } from "framer-motion"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import LanguagePicker from "../components/languagepicker"
import AboutCanvas from "../components/canvasabout"
import * as styles from "../styles/about.module.scss"
import AndSymbol from "../svg/andsymbol.inline.svg"
import CircleSymbol from "../svg/circlesymbol.inline.svg"

const clientQuotesEng = [
  {
    clientName: "Charlene Putney, Laika",
    clientCity: "Copenhagen, Denmark",
    clientQuote:
      "Maxi and Madeleine have a wonderful ability to turn dreams and ideas into beautiful, working products - in record time, with a smile. I cannot recommend them highly enough!",
  },
  {
    clientName: "Peter Herrmann, Kiwika AG",
    clientCity: "Nidau, Switzerland",
    clientQuote:
      "We are more than happy! We have been working together for over a year now; in that time we have achieved all our objectives thanks to the competent implementation.",
  },
  {
    clientName: "Kelsey Hendershot, Condé Nast",
    clientCity: "New York, USA",
    clientQuote:
      "Great work on the Global Design Talks pages; the team has been so flexible and patient with us, fully working on our schedule!",
  },
  {
    clientName: "Angelica Kaufmann, Inhalation Ltd.",
    clientCity: "London, UK",
    clientQuote:
      "Working with Vanilla Noir is a constantly stimulating experience. They cared about every aspect of the project with precision, punctuality, and creativity.",
  },{
    clientName: "Yuen Kew Li, Wonderbly",
    clientCity: "London, UK",
    clientQuote:
      "A MASSIVE thank you for working on this project with us. This has by far been the most challenging QA we have faced, and you have been an absolute hero grinding through those Trello cards and translator comments."
  },{
    clientName: "Jeff Wakeman, StorySlab",
    clientCity: "New York, USA",
    clientQuote: "Great designs! We're super happy with the end result.",
  },
]

const clientQuotesDe = [
  {
    clientName: "Charlene Putney, Laika",
    clientCity: "Kopenhagen, Dänemark",
    clientQuote:
      "Maxi und Madeleine haben die wundervolle Fähigkeit, Träume und Ideen in schöne, funktionierende Produkte zu verwandeln - in Rekordzeit und mit einem Lächeln.",
  },
  {
    clientName: "Peter Herrmann, Kiwika AG",
    clientCity: "Nidau, Schweiz",
    clientQuote:
      "Wir sind sehr zufrieden! Wir arbeiten inzwischen über ein Jahr zusammen; in der Zeit haben wir dank der kompetenten Umsetzung all unsere Ziele erreicht.",
  },
  {
    clientName: "Kelsey Hendershot, Condé Nast",
    clientCity: "New York, USA",
    clientQuote:
      "Großartige Arbeit für unsere Event-Seite. Die beiden waren so flexibel und geduldig mit uns und haben sich voll und ganz an unseren Zeitplan angepasst!",
  },
  {
    clientName: "Angelica Kaufmann, Inhalation Ltd.",
    clientCity: "London, UK",
    clientQuote:
      "Die Zusammenarbeit mit Vanilla Noir war eine anregende Erfahrung. Sie kümmern sich um alle Aspekte eines Projekts mit Präzision, Pünktlichkeit und Kreativität.",
  },{
    clientName: "Yuen Kew Li, Wonderbly",
    clientCity: "London, UK",
    clientQuote:
      "Ein riesiges Dankeschön für die Arbeit an diesem Projekt mit uns. Dies war bei weitem die anspruchsvollste Qualitätssicherung, mit der wir konfrontiert waren, und ihr wart absolute Helden, sich durch all die Trello-Karten und Übersetzerkommentare zu arbeiten."
  },
  {
    clientName: "Jeff Wakeman, StorySlab",
    clientCity: "New York, USA",
    clientQuote: "Tolle Designs! Wir sind sehr zufrieden mit dem Endergebnis.",
  },
]

function About() {
  const { t, i18n } = useTranslation()
  const isGerman = i18n.language === "de" ? true : false
  const clientQuotes = isGerman ? clientQuotesDe : clientQuotesEng

  const [quoteIndex, setQuoteIndex] = React.useState(0)

  //ScrollMotion Value
  const [isDark, setDark] = React.useState(false)
  const [isMobile, setisMobile] = React.useState(false)
  const { scrollYProgress } = useScroll()

  //Wrapper
  const wrapperX = useSpring(
    useTransform(scrollYProgress, [0, 0.65], [1, -11200]),
    { stiffness: 400, damping: 90 }
  )
  const wrapperY = useSpring(
    useTransform(scrollYProgress, [0, 0.75, 1], [1, 1, -2000]),
    { stiffness: 400, damping: 90 }
  )

  //LanguagePicker Animation
  const langX = useSpring(
    useTransform(scrollYProgress, [0, 0.075], [1, -4000]),
    { stiffness: 400, damping: 90 }
  )

  //Scroll Progress Indicator
  const yRange = useTransform(scrollYProgress, [0, 0.9], [0, 1])
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 })

  //White Bar
  const barAlpha = useTransform(
    scrollYProgress,
    [0, 0.34, 0.36, 0.45],
    [0, 0, 1, 1],
    { stiffness: 400, damping: 90 }
  )

  //Circles moving together
  const c1 = useTransform(
    scrollYProgress,
    [0.1, 0.235, 0.245, 0.35],
    [-150, 100, 100, -150],
    { stiffness: 400, damping: 90 }
  )

  //Draw Icons
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: i => {
      const delay = -0.2 + i * 0.8
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      }
    },
  }


  React.useEffect(
    () =>
      scrollYProgress.onChange(latest => {
        //Set Page to dark
        //Mobile
        if (window.innerWidth < 1200) {
          if (scrollYProgress.current > 0.35) {
            setDark(true)
          } else {
            setDark(false)
          }
        }
        //Desktop
        if (window.innerWidth >= 1200) {
          if (
            (scrollYProgress.current > 0.075 &&
              scrollYProgress.current < 0.3) ||
            scrollYProgress.current > 0.65
          ) {
            setDark(true)
          } else {
            setDark(false)
          }
        }
      }),
    []
  )

  //Init
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setisMobile(false)
      } else {
        setisMobile(true)
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <Layout
      type="about"
      isDark={isDark}
      isMobile={isMobile}
      key={i18n.language}
    >
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: "easeOut", duration: 1.5, delay: 1 }}
        className="lang-picker"
      >
        <motion.div style={{ x: langX }}>
          <LanguagePicker isdark={isDark} disabled={isMobile}></LanguagePicker>
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: 1.5, delay: 1 }}
      >
        <div
          className={
            isDark ? "progress-indicator light" : "progress-indicator dark"
          }
        >
          <span className="scroll">scroll</span>
          <div className="progress">
            <svg className="progress-icon" viewBox="0 0 60 60">
              <motion.path
                fill="none"
                strokeWidth="10"
                stroke="#363636"
                strokeDasharray="0 1"
                d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                style={{
                  pathLength,
                  rotate: 90,
                  translateX: 5,
                  translateY: 5,
                  scaleX: -1, // Reverse direction of line animation
                }}
              />
            </svg>
          </div>
        </div>
      </motion.div>
      <div className="about-container-horizontal">
        <motion.div
          className="about-wrapper"
          style={isMobile ? {} : { x: wrapperX, y: wrapperY }}
        >
          {isMobile ? <></> : <AboutCanvas />}
          <motion.div
            style={isMobile ? {} : {}}
            className={styles.aboutContainer}
          >
            <section className="subpage-title-section about-title-section">
              <h1 className="subpage-title dark">
                <span className="subpage-title-top">{t("abouttitle1")}</span>
                <br></br>
                <span className="subpage-title-middle-about">
                  {" "}
                  {t("abouttitle2")}
                </span>
                <br></br>
                <span className="subpage-title-middle2">
                  <i>{t("abouttitle3")}</i>
                </span>
                <br></br>
                <span className="subpage-title-bottom">{t("abouttitle4")}</span>
                <br></br>
              </h1>
              <div className="subpage-subtitle dark">
                <hr></hr>
                <span className="lowercase">{t("aboutus")}</span>
              </div>
            </section>
          </motion.div>
          {isMobile ? (
            <div className={styles.mobileImage}>
              <StaticImage
                src="../images/hands.jpg"
                alt={
                  isGerman
                    ? "Hände, die nach einem Licht über ihnen greifen"
                    : "hands, reaching for the light above"
                }
              ></StaticImage>
            </div>
          ) : (
            <></>
          )}
          <section
            className={[styles.symbolSection, "about-page-content"].join(" ")}
            id="about-page-content-1"
          >
            <motion.div
              style={isMobile ? {} : { x: 2000 }}
              className={styles.aboutContainer}
            >
              <div className={styles.symbolBox}>
                <div className={styles.symbolImage}>
                  <div className="animations">
                    <motion.svg
                      width="300"
                      height="300"
                      viewBox="-20 -20 230 230"
                      initial="hidden"
                      whileInView="visible"
                    >
                      <motion.path
                        d="M185.42,166.57c-15.97,0-69.88,16.86-133.69-84.13C33.66,53.85,29.42,7.5,83.33,7.5c41.71,0,65.93,53.62-31.6,74.95-32.14,7.03-60.49,46.29-33.34,78.41,27.15,32.12,116.87,10.08,146.05-92.13"
                        stroke="#eeebeb"
                        strokeWidth="10"
                        //stroke-linecap="round"
                        fill="none"
                        variants={draw}
                        custom={1}
                      />
                    </motion.svg>
                  </div>
                </div>
                <div className={styles.symbolImageMobile}>
                  <AndSymbol />
                </div>
                <div className={styles.symbolText}>
                  <p className={isDark ? "light" : "dark"}>{t("abouttext1")}</p>
                </div>
              </div>
            </motion.div>
            <motion.div
              style={isMobile ? {} : { x: 4000 }}
              className={styles.aboutContainer}
            >
              <div className={styles.symbolBox}>
                <div className={styles.symbolImage}>
                  <div className="animations">
                    <motion.svg
                      width="800"
                      height="200"
                      //   viewBox="0 0 300 200"
                    >
                      <motion.circle
                        cx="100"
                        cy="100"
                        r="80"
                        stroke="#EEEBEB"
                        style={isMobile ? {} : { x: c1 }}
                        custom={1}
                      />
                      <motion.circle
                        cx="200"
                        cy="100"
                        r="80"
                        stroke="#EEEBEB"
                        custom={2}
                      />
                    </motion.svg>
                  </div>
                </div>
                <div
                  className={styles.symbolImageMobile}
                  id={styles.circleSymbol}
                >
                  <CircleSymbol />
                </div>
                <div className={styles.symbolText}>
                  <p className={isDark ? "light" : "dark"}>{t("abouttext2")}</p>
                </div>
              </div>
            </motion.div>
          </section>
          <section className="about-page-content" id="about-page-content-2">
            <motion.div
              style={isMobile ? {} : { x: 6500 }}
              className={styles.aboutContainer}
            >
              <motion.div
                style={isMobile ? {} : { opacity: barAlpha }}
                className="horizontal-bar"
              ></motion.div>
              <div className={styles.profileIntroduction}>
                <h2 className={isDark ? "light" : "dark"}>Meet the Team</h2>
                <p className={isDark ? "light" : "dark"}>{t("abouttext3")}</p>
              </div>
            </motion.div>
            <div className={[styles.profileBox].join(" ")}>
              <div>
                <motion.div
                  style={isMobile ? {} : { x: 7500 }}
                  className={styles.aboutContainer}
                >
                  <div className={[styles.profile, "dark"].join(" ")}>
                    <div className={styles.profileCol_1}>
                      <StaticImage
                        objectFit="contain"
                        src={"../images/madeleine-bw.png"}
                        className={[styles.profileImg].join(" ")}
                        alt={
                          isGerman
                            ? "Portrait von Madeleine Frister"
                            : "Portrait of Madeleine Frister"
                        }
                      ></StaticImage>
                    </div>
                    <div className={styles.profileCol_2}>
                      <h6 className={isDark ? "light" : "dark"}>
                        co-founder | design lead
                      </h6>
                      <h5 className={isDark ? "light" : "dark"}>
                        Madeleine Frister
                      </h5>
                      <p className={isDark ? "light" : "dark"}>
                        {t("aboutmadeleine")}
                      </p>
                      {/* <a href={madeleineCVURL} target="_blank">
                        <p className={isDark ? "light resume" : "dark resume"}>
                          {t("resume")}
                        </p>
                      </a> */}
                      <a href="/resume-mf.pdf" target="_blank">
                        <p className={isDark ? "light resume" : "dark resume"}>
                          {t("resume")}
                        </p>
                      </a>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  style={isMobile ? {} : { x: 9000 }}
                  className={styles.aboutContainer}
                >
                  <div className={[styles.profile, "dark"].join(" ")}>
                    <div className={styles.profileCol_1}>
                      <StaticImage
                        objectFit="contain"
                        src={"../images/maxi-bw4.png"}
                        className={[styles.profileImg].join(" ")}
                        alt={
                          isGerman
                            ? "Portrait von Maximilian Croissant"
                            : "Portrait of Maximilian Croissant"
                        }
                      ></StaticImage>
                    </div>
                    <div className={styles.profileCol_2}>
                      <h6 className={isDark ? "light" : "dark"}>
                        co-founder | development lead
                      </h6>
                      <h5 className={isDark ? "light" : "dark"}>
                        Maximilian Croissant
                      </h5>
                      <p className={isDark ? "light" : "dark"}>
                        {t("aboutmaxi")}
                      </p>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </section>
          <motion.div
            style={isMobile ? {} : { x: 11200 }}
            className={styles.aboutContainer}
          >
            <section
              className={["subpage-title-section", styles.aboutSection].join(
                " "
              )}
              id={styles.aboutImageSection}
            >
              <h1
                className={[
                  styles.subpageTitle,
                  isDark ? "light" : "dark",
                ].join(" ")}
              >
                <span>{t("aboutsubtitle1")}</span>
                <br></br>
                <span> {t("aboutsubtitle2")}</span>
                <br></br>
                <span>{t("aboutsubtitle3")}</span>
                <br></br>
              </h1>
              <StaticImage
                src="../images/seal2.png"
                className={styles.sealImage}
                alt={
                  isGerman
                    ? "eine sehr zufriedene Robbe, die in die Luft schaut"
                    : "a very satisfied seal, looking into the sky"
                }
              ></StaticImage>
            </section>
          </motion.div>
          <motion.div
            style={isMobile ? {} : { x: 11200, y: 1300 }}
            className={styles.aboutContainer}
          >
            <section
              className={["subpage-title-section", styles.aboutSection].join(
                " "
              )}
              id={styles.aboutPageClientQuote}
            >
              <div className={isDark ? "light" : "dark"}>
                <span className={styles.quoteIcon}>“</span>
                {clientQuotes.map((quote, index) => {
                  if (quoteIndex != index) return
                  return (
                    <div className={styles.quoteContainer} key={"qu-" + index}>
                      <p className={styles.clientQuote}>{quote.clientQuote}</p>
                      <br />
                      <p className={styles.clientName}>{quote.clientName}</p>
                      <p className={styles.clientName}>{quote.clientCity}</p>
                      <br />
                    </div>
                  )
                })}
                <div className={styles.buttonBox}>
                  {clientQuotes.map((quote, index) => (
                    <div
                      className={
                        quoteIndex === index
                          ? [styles.buttonBox__button, styles.active].join(" ")
                          : styles.buttonBox__button
                      }
                      onClick={() => setQuoteIndex(index)}
                      key={"bu-" + index}
                    ></div>
                  ))}
                </div>
              </div>
            </section>
            <div className={styles.designRushLink}>
              <span>
                Review Vanilla Noir on{" "}
                <a
                  href="https://www.designrush.com/agency/profile/vanilla-noir"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DesignRush
                </a>
              </span>
            </div>
          </motion.div>
        </motion.div>
      </div>
      <div className="emptyspace aboutpage"></div>
    </Layout>
  )
}

export default About

export const Head = () => (
  <Seo
    title="About Us"
    titleDE="Über Uns"
    description="We are a small team with big ambitions: Dedicated, bold, creative and customer-focused. Get to know us and start your personal web project."
    descriptionDE="Wir sind ein kleines Team mit großen Ambitionen: Engagiert, mutig, kreativ und kundennah. Lernen Sie uns kennen und starten Sie Ihr Web-Projekt."
  />
)
