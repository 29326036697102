// extracted by mini-css-extract-plugin
export var aboutContainer = "about-module--aboutContainer--950d9";
export var aboutImageSection = "about-module--aboutImageSection--675fd";
export var aboutPageClientQuote = "about-module--aboutPageClientQuote--9de10";
export var aboutSection = "about-module--aboutSection--06f02";
export var active = "about-module--active--628b8";
export var buttonBox = "about-module--buttonBox--cb63d";
export var buttonBox__button = "about-module--buttonBox__button--4244d";
export var circleSymbol = "about-module--circleSymbol--7e724";
export var clientName = "about-module--clientName--533ba";
export var clientQuote = "about-module--clientQuote--1912d";
export var designRushLink = "about-module--designRushLink--59266";
export var mobileImage = "about-module--mobileImage--b0b8e";
export var opacityIn = "about-module--opacity-in--d3c17";
export var profile = "about-module--profile--412ba";
export var profileBox = "about-module--profileBox--f1bb6";
export var profileCol_1 = "about-module--profileCol_1--4152c";
export var profileCol_2 = "about-module--profileCol_2--9ac7e";
export var profileImg = "about-module--profileImg--c58bd";
export var profileIntroduction = "about-module--profileIntroduction--8a348";
export var quoteContainer = "about-module--quoteContainer--e6cc3";
export var quoteIcon = "about-module--quoteIcon--f40f6";
export var sealImage = "about-module--sealImage--86d83";
export var subpageTitle = "about-module--subpageTitle--b44d7";
export var symbolBox = "about-module--symbolBox--42abb";
export var symbolImage = "about-module--symbolImage--7eb8e";
export var symbolImageMobile = "about-module--symbolImageMobile--d35a5";
export var symbolSection = "about-module--symbolSection--3a401";
export var symbolText = "about-module--symbolText--2e737";